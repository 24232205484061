import {useCallback, useState} from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {useFormik} from 'formik'
import Swal from 'sweetalert2'
import clsx from 'clsx'
import {updateTime} from './core/_requests'
import {Loading} from '../../components/loading/Loading'
import {initialTimeTracking, TimeTracking} from './core/_models'
import {ID, QUERIES} from '../../../helpers'
import {Task} from '../../../../app/modules/project/components/tasks-list/core/_models'
import {InputHour} from '../../../helpers'
import {getTaskAdjustTimeById} from '../../../../app/modules/project/components/tasks-list/core/_requests'
import {useListView} from '../../../../app/modules/project/components/tasks-list/core/ListViewProvider'
import {useQueryResponse} from '../../../../app/modules/project/components/tasks-list/core/QueryResponseProvider'
import {useAuth} from '../../../../app/modules/auth'

type Props = {
  handleClose: () => void
  taskId: ID
}

const AdjustTimeUserModalForm = ({handleClose, taskId}: Props) => {
  const intl = useIntl()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  const [show, setShow] = useState(false)
  const [showDashHour, setShowDashHour] = useState(false)
  const [showBtn, setShowBtn] = useState(false)

  const [text, setText] = useState('')
  const [textlabel, setTextLabel] = useState('')
  const [type, setType] = useState('')

  var today = new Date().toISOString().split('T')[0]

  const {
    isLoading,
    data: task,
    error,
  } = useQuery(
    `${QUERIES.TASKS_LIST}-adjust-time-task-${taskId}`,
    () => {
      return getTaskAdjustTimeById(taskId)
    },
    {
      cacheTime: 0,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
      refetchOnWindowFocus: false,
    }
  )

  const [data, setData] = useState<Task>(task?.tasks!)
  const {refetch} = useQueryResponse()
  const CurrentTheme = window.localStorage.getItem('kt_theme_mode_value') || ''
  let theme = ''
  let colorLabel = ''
  CurrentTheme === 'dark' ? (theme = '#353b48') : (theme = '#fff')
  CurrentTheme === 'dark' ? (colorLabel = 'white') : (colorLabel = 'black')
  const [colorAdd, setColorAdd] = useState(colorLabel)
  const [colorDash, setColorDash] = useState(colorLabel)
  const {currentUser} = useAuth()
  console.log(currentUser)

  function addHour() {
    setTextLabel('Adicionar horas')
    setText('Adicionar')
    setShow(true)
    setShowDashHour(false)
    setType('add')
    setColorAdd('009ef7')
    setColorDash(colorLabel)
    setShowBtn(false)
  }
  function dashHour() {
    setTextLabel('Remover horas')
    setText('Remover')
    setShow(false)
    setShowDashHour(true)
    setType('remove')
    setColorAdd(colorLabel)
    setColorDash('009ef7')
    setShowBtn(false)
  }

  function openBtn() {
    setShowBtn(true)
  }

  const [timetrackingEdit] = useState<TimeTracking>({
    begin_date: initialTimeTracking.begin_date,
    end_time: initialTimeTracking.end_time,
  })

  const formik = useFormik({
    initialValues: timetrackingEdit,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await updateTime(values).then((response) => {
          if (response?.code === 200) {
            Swal.fire({
              title: intl.formatMessage({id: 'ALERT.LABEL.UPDATE.TITLE'}),
              icon: 'success',
              html: '<h5>' + response?.message + '</h5>',
              showDenyButton: false,
              showCancelButton: false,
              color: '#fff',
              background: theme,
              confirmButtonColor: '#009ef7',
              confirmButtonText: `Atualizar página`,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload()
              }
            })
          } else {
            Swal.fire({
              icon: 'error',
              html: '<h5>' + response?.message + '</h5>',
              background: theme,
              confirmButtonColor: '#009ef7',
              color: '#fff',
            })
          }
        })
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const sendAdjustForm = useCallback(
    async (e: any) => {
      if (type == 'add') {
        // @ts-ignore
        const createdAt = new Date(currentUser.created_at)
        createdAt.setHours(createdAt.getHours() + 3)
        createdAt.setHours(0, 0, 0, 0);

        const selectedDate = new Date(formik.values.date!)
        selectedDate.setHours(selectedDate.getHours() + 3)
        selectedDate.setHours(0, 0, 0, 0);

        if (selectedDate < createdAt) {
          const resultSwal = await Swal.fire({
            title: 'Confirmação de lançamento retroativo',
            icon: 'warning',
            html: '<h5>Você está prestes a registrar horas em uma data anterior à data de criação do usuário. Deseja continuar com esta ação?</h5>',
            showDenyButton: false,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            color: '#fff',
            background: theme,
            confirmButtonColor: '#009ef7',
            confirmButtonText: `Continuar`,
            reverseButtons: true
          })

          if(!resultSwal.isConfirmed)
            return
        }
      }

      formik.setFieldValue('type', type)
      formik.setFieldValue('task_id', taskId)
      formik.submitForm()

      handleClose()
      setItemIdForUpdate(taskId)
    },
    [formik]
  )

  return (
    <>
      <form
        id='kt_modal_add_project_milestone_form'
        className='form'
        onSubmit={sendAdjustForm}
        noValidate
      >
        <div className='row mb-5 mt-0 pt-0'>
          <div className='col-md-12'>
            <p>Selecione uma data e ajuste as horas alocadas conforme necessário:</p>
          </div>
          <div className='col-md-3'>
            <label htmlFor='extra_cost' className='d-block fw-bold mb-2'>
              {intl.formatMessage({id: 'FORM.INPUT.NAME.TIMETRACKING_TOTALTIME'})}
            </label>

            <input
              {...formik.getFieldProps('total_time_bd')}
              type='text'
              readOnly={true}
              disabled={true}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              value={task?.tasks.total_time_format!}
              autoComplete='off'
            />
          </div>

          <div className='col-md-1' style={{display: 'inline-grid', marginTop: '30px'}}>
            <div onClick={() => addHour()}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-plus-lg cursor-pointer '
                viewBox='0 0 16 16'
                style={{color: colorAdd}}
              >
                <path
                  fill-rule='evenodd'
                  d='M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z'
                />
              </svg>
            </div>

            <div onClick={() => dashHour()}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-dash-lg cursor-pointer '
                viewBox='0 0 16 16'
                style={{color: colorDash}}
              >
                <path
                  fill-rule='evenodd'
                  d='M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z'
                />
              </svg>
            </div>
          </div>

          {show && (
            <div className='col-md-3'>
              <label className='d-block fw-bold mb-2'>
                {intl.formatMessage({id: 'FORM.INPUT.NAME.DATE'})}
              </label>
              <input
                type='date'
                max={today}
                disabled={formik.isSubmitting}
                {...formik.getFieldProps('date')}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                onChange={(e: any) => {
                  formik.setFieldValue('date', e.target.value)
                  openBtn()
                }}
                autoComplete='off'
              />
            </div>
          )}

          {showDashHour && (
            <div className='col-md-4'>
              <label className='d-block fw-bold mb-2'>
                {intl.formatMessage({id: 'FORM.INPUT.NAME.DATE'})}
              </label>

              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                {...formik.getFieldProps('date')}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('date', e.target.value)
                  openBtn()
                }}
              >
                <option>{intl.formatMessage({id: 'TASK.LABEL.SELECT_DAY_WORKED'})}</option>
                {task?.tasks.dates_work?.map((item, index) => (
                  <option key={index} value={item.created_at!}>
                    {item.date_format + ' - ' + item.output}
                  </option>
                ))}
              </select>
            </div>
          )}

          {show || showDashHour ? (
            <div className='col-md-3'>
              <label htmlFor='extra_cost' className='d-block fw-bold mb-2'>
                {textlabel}
              </label>
              <InputHour
                {...formik.getFieldProps('total_time_bd')}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                name='total_time'
                placeholder='00:00'
                disabled={formik.isSubmitting}
                onChange={(e: any) => {
                  const numbers = e.target.value.replace(/\D/g, '')
                  let hours = numbers.slice(0, -2) || '0'
                  let minutes = numbers.slice(-2).padStart(2, '0')
                  if (hours.length > 2) hours = hours.slice(-2)
                  hours = hours.padStart(2, '0')

                  formik.setFieldValue('total_time', hours + ':' + minutes)
                }}
              />
            </div>
          ) : null}
        </div>

        {/* begin::Actions */}
        {showBtn ? (
          <div className='text-center pt-6'>
            <button
              type='button'
              className='btn btn-primary btn-sm px-8'
              data-kt-users-modal-action='submit'
              value={type}
              onClick={sendAdjustForm}
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>{text}</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        ) : null}
        {/* end::Actions */}
      </form>
      {formik.isSubmitting && <Loading />}
    </>
  )
}

export {AdjustTimeUserModalForm}
